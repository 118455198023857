import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Menu, MenuItem, Popover, Slider, SvgIcon, TextField, ThemeProvider, Tooltip, Typography, createTheme, keyframes } from "@mui/material";
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from "@mdi/js";
import { clearChat, addChatHistory } from "../../actions/lobby";
import { Check, Close, Send as SendIcon } from '@mui/icons-material';
import { shortcuts, smileyMap } from "./shortcuts";
import { presentNames, presentPts } from "./present_details";
import { GlobalTypes } from "../../action_types";
import { addChatTab, setNotification, setRoomConfig, setRoomMaintenance, setReportDialog } from "../../actions/global";
import UserActions from "../Lobby/UserActions";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import Focusable from "../Focusable";
import { ReactComponent as TabIcon } from './icons/tab.svg';
import dayjs from "dayjs";
import { smileys } from "./smileys";
import { presents } from "./presents/index";
import axios, { AxiosError } from "axios";
import Presents from "./Presents";
import ContextMenuHandler from "../../utils/ContextMenuHandler";
import { roles } from "../../global/role";
import { ERROR_CODES } from "../../global/error";
import Chatbar from "./Chatbar";
import { AxiosClient } from "../../global/axios";
import { getPath } from "../../global/route";
import { escape } from "../../global/emoji";
import { CHATBOX_TYPES } from "../../global/chatbox";
import { loadedResources } from "../../global/resources";


const humanize = require('humanize-number');

const styles = theme => ({
    text: {
        fontWeight: '400 !important',
    },
});

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
        },
    },
});

class Chatbox extends React.Component {
    constructor(props) {
        super(props);

        this.userContextMenuHandler = new ContextMenuHandler(
            (e, user_id) => {
                this.userContextRequest = true;
                this.onUserRightClicked(e, user_id);
            }
        );

        this.chatboxContextMenuHandler = new ContextMenuHandler(
            (e) => {
                if (this.userContextRequest) {
                    return;
                }

                this.onContextMenu(e);
            }
        );

        this.state = {
            autoScroll: true,
            smileys: [],
            smileyAnchorEl: null,
            presents: [],
            presentAnchorEl: null,
            sendingPresent: null,
            height: props.height ?? (isMobile ? 80 : 60),
            userAnchor: null,
            userAnchorPos: null,
            contextUser: null,
            contextMenuPosition: null,
            userReportActive: {},
            reportedMessages: [],
            reportPlayerDialog: false,
            slowModeDialog: false,
            slowMode: (props.roomConfig?.slowMode ?? 0) / 1000,
            totalChatLogs: 0,
            page: 1,
            size: 40,
            sendingGlobalMessage: false,
            anonymousGlobalMessage: false,
            disbandingTable: false,
            pausingGame: false,
        }

        this.sendRef = React.createRef();
        this.smileyButtonRef = React.createRef();
        this.presentButtonRef = React.createRef();
        this.scrollRef = React.createRef();
        this.chatbarRef = React.createRef();
        this.smileyCache = {};
        this.heights = [0, 25, 50, 75, 100];
        this.userTabPrefix = '';
        this.userTabIndex = 0;
        this.msgCache = {};
    }

    componentDidMount() {
        this.props.onRef?.(this);
        this.loadSmileys();
        this.loadPresents();

        /*
        if (this.props.type === 'pm') {
            this.getChatHistory();
        }
        */

        if (this.props.allowExpand) {
            const h = localStorage.getItem('chatboxHeight');
            const dh = parseFloat(h);
            if (dh) {
                this.setHeight(dh);
            }
            else {
                this.props.onHeightChanged?.(this.state.height);
            }
        }

        window.addEventListener('keydown', this.keyDownListener);
    }

    setHeight = (height) => {
        this.setState({ height });
        this.props.onHeightChanged?.(height);
    }

    keyDownListener = (e) => {
        if (document.activeElement.nodeName !== 'INPUT' &&
            document.activeElement.nodeName !== 'TEXTAREA' &&
            document.activeElement.nodeName !== 'SELECT' &&
            !e.ctrlKey) {
            this.sendRef.current?.focus()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    componentDidUpdate(prevProps) {
        if (this.props.messages !== prevProps.messages) {
            setTimeout(() => {
                if (this.state.size !== 40) {
                    const secondChild = this.scrollRef.current.childNodes[0];
                    secondChild?.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        }

        /*
        if (this.props.selectedTab !== prevProps.selectedTab && this.props.type === 'pm') {
            this.getChatHistory();
        }

        if (this.props.user !== prevProps.user && this.props.user && this.props.type === 'pm') {
            this.getChatHistory();
        }
        */

        if (this.props.height !== prevProps.height) {
            this.setHeight(this.props.height);
        }

        if (prevProps.generalSettings?.msg_time !== this.props.generalSettings?.msg_time) {
            this.msgCache = {};
            this.forceUpdate();
        }
    }

    getChatHistory = async () => {
        const { selectedTab, user } = this.props;
        const { page, size } = this.state;

        if (!selectedTab || !user) {
            return;
        }

        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/user/chat.messages`, {
                params: {
                    page,
                    size,
                    to: selectedTab,
                }
            });

            this.props.addChatHistory(selectedTab, resp.data?.data);
            this.setState({ totalChatLogs: resp.data?.total });

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    };

    getOlderMessages = async () => {
        this.setState({ size: this.state.size + 10 }, async () => await this.getChatHistory());
    }

    loadSmileys = () => {
        /*
        const smileys = require.context('./smileys', false, /\.(gif|png|jpe?g|svg)$/).keys().map((filename) => {
            return filename.replace('./', '');
        });
        */

        this.setState({ smileys: smileys });
    }

    loadPresents = () => {
        /*
        const presents = require.context('./presents', false, /\.(gif|png|jpe?g|svg)$/).keys().map((filename) => {
            return filename.replace('./', '');
        });
        */

        this.setState({ presents: presents });
    }

    replaceStringWithEmoji = (msg, u) => {
        if (msg.id in this.smileyCache) {
            return (
                <span className={clsx(u?.username_color && (!u.svu || u.svu > new Date()) ? `YaziRenk${u.text_color}` : '')}>
                    {this.smileyCache[msg.id]}
                </span>
            );
        }

        const message = msg.message;
        const pattern = new RegExp(
            Object.keys(smileyMap).map(escape).join('|'),
            'g'
        );

        const arr = message?.match(pattern);
        const parts = [];
        let index1 = 0;

        arr?.forEach((match) => {
            let index2 = message.indexOf(match, index1);
            if (index2)
                parts.push(
                    message.substring(index1, index2)
                );

            parts.push(<img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/smileys/${smileyMap[match]}`]} alt={smileyMap[match]} width={24} height={24} style={{ display: 'inline', backgroundColor: 'transparent' }} />);
            index1 = index2 + match.length;
        });

        parts.push(message?.substring(index1));
        this.smileyCache[msg.id] = parts;

        return (
            <span className={clsx(u?.username_color && (!u.svu || u.svu > new Date()) ? `YaziRenk${u.text_color}` : '')}>
                {parts}
            </span>
        );
    };

    onTabUserWithUsername = (username) => {
        this.chatbarRef?.onTabUserWithUsername(username);
    }

    onUserRightClicked = (e, userId) => {
        if (!userId) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const user = this.props.usersById[userId] ?? this.props.friendsById[userId];
        const touch = e.touches?.[0];

        if (touch) {
            this.setState({ userAnchor: null, userAnchorPos: { left: touch.clientX, top: touch.clientY }, contextUser: user, contextMenuPosition: null });
        }
        else {
            this.setState({ userAnchor: e.currentTarget, userAnchorPos: null, contextUser: user, contextMenuPosition: null });
        }
    }

    onUserReport = (v) => {
        this.setState({ userReportActive: { active: true, reported_user: v.user.id } });
    }

    doOpenReportDialog = () => {
        this.props.setReportDialog({
            active: true,
            room: this.props.selectedTab,
            pm: Boolean(this.props.pm),
            reportedMessages: this.state.reportedMessages.sort((a, b) => a.ts - b.ts),
            reportedUserId: this.state.userReportActive.reported_user,
        });

        this.setState({ userReportActive: { ...this.state.userReportActive, reported_user: null }, reportedMessages: [] }, () => this.setState({ userReportActive: {} }))
    };

    sendPresent = () => {
        const { sendingPresent } = this.state;
        const { selectedTab } = this.props;

        if (!sendingPresent) {
            return;
        }

        this.props.socket?.emit('send_message', { message: null, room: selectedTab, present: sendingPresent, pm: Boolean(this.props.pm) });
        this.setState({ sendingPresent: null });
    }

    getRoomConfig = async () => {
        try {
            const resp = await AxiosClient().get(`${process.env.REACT_APP_API_URL}/v1/admin/room/config`);

            this.props.setRoomConfig(resp.data);

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    onContextMenu = (e) => {
        const { type, dmUserId } = this.props;
        const { userAnchor, userAnchorPos } = this.state;

        e.preventDefault();

        if (type === CHATBOX_TYPES.PM) {
            this.userContextMenuHandler.onContextMenu(e, dmUserId);
            return;
        }

        if (userAnchor || userAnchorPos) {
            return;
        }

        const touch = e.touches?.[0];

        if (touch) {
            this.setState({ contextMenuPosition: { left: touch.clientX, top: touch.clientY } });
        }
        else {
            this.setState({ contextMenuPosition: { left: e.clientX, top: e.clientY } });
        }

    }

    changeLobbyChat = async (allowLobbyChat = false) => {
        const roomConfig = { ...this.props.roomConfig, allowLobbyChat };

        try {
            const resp = await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/room/config`, roomConfig);

            this.props.setNotification({ message: `Lobi sohbeti ${allowLobbyChat ? 'açıldı' : 'kapatıldı'}.`, severity: GlobalTypes.NOTIFY_SUCC });
            this.getRoomConfig()

        } catch (err) {
            console.error(err);
            this.props.setNotification({ message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.', severity: GlobalTypes.NOTIFY_ERR });
        } finally {
            this.setState({ contextMenuPosition: null });
        }
    }

    clearLobbyChat = () => {
        this.props.socket?.emit('clear_lobby_chat');
        this.setState({ contextMenuPosition: null });
    }

    startMaintenance = async () => {
        try {
            this.setState({ contextMenuPosition: null });

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/server/maintenance`, {
                enabled: true,
            });

            this.props.setNotification({ message: 'Bakım modu aktif edildi.', severity: GlobalTypes.NOTIFY_SUCC });
            this.props.setRoomMaintenance(true);
        }
        catch (e) {
            console.error(e);
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }

                if (code === 214) {
                    this.props.setRoomMaintenance(true);
                }
            }

            this.props.setNotification({ message, severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    endMaintenance = async () => {
        try {
            this.setState({ contextMenuPosition: null });

            await AxiosClient().post(`${process.env.REACT_APP_API_URL}/v1/admin/server/maintenance`, {
                enabled: false,
            });

            this.props.setNotification({ message: 'Bakım modu kapatıldı.', severity: GlobalTypes.NOTIFY_SUCC });
            this.props.setRoomMaintenance(false);
        }
        catch (e) {
            console.error(e);
            let message = ERROR_CODES[2];

            if (e instanceof AxiosError) {
                const code = e.response?.data?.error?.code;
                if (code in ERROR_CODES) {
                    message = ERROR_CODES[code];
                } else {
                    message = e.message;
                }

                if (code === 215) {
                    this.props.setRoomMaintenance(false);
                }
            }

            this.props.setNotification({ message, severity: GlobalTypes.NOTIFY_ERR });
        }
    }

    setSlowMode = () => {
        const { socket } = this.props;
        socket?.emit('set_slow_mode', { slowMode: this.state.slowMode * 1000 });

        this.props.setNotification({ message: `Yavaş mod ${this.state.slowMode} saniye olarak ayarlandı.`, severity: GlobalTypes.NOTIFY_SUCC });
        this.setState({ slowModeDialog: false, contextMenuPosition: null });
    }

    smileyMenu = () => {
        const { type } = this.props;
        const onClose = (e, smiley) => {
            e.stopPropagation();
            e.preventDefault();

            if (!smiley)
                this.setState({ smileyAnchorEl: null });
            else
                this.chatbarRef?.onSmileySelected(smiley);
        }

        return (
            <ThemeProvider theme={theme}>
                <Box position={"absolute"} display={this.state.smileyAnchorEl ? "block" : "none"} zIndex={10}
                    overflow={"auto"} border={"2px solid #afafaf"} bgcolor={"white"}
                    sx={{ overscrollBehavior: 'none' }}
                    className={clsx(
                        "max-h-full top-4 sm:bottom-14 rounded-md",
                        isMobile ? "bottom-8" : "bottom-10",
                        type === CHATBOX_TYPES.TABLE ? "right-72 xl:right-96" : "right-0"
                    )}
                    onBlur={() => this.setState({ smileyAnchorEl: null })}
                >
                    <Grid container columns={18}
                        className={clsx(
                            "max-h-[75vh]",
                            "!w-[204px] sm:!w-[324px] md:!w-[342px] lg:!w-[360px] xl:!w-[378px]"
                        )}
                    >
                        {this.state.smileys.map((smiley) => {
                            if (!(smiley in shortcuts))
                                return null;

                            return (
                                <Grid item key={smiley} xs={3} sm={2} onClick={(e) => onClose(e, smiley)}
                                    sx={{
                                        cursor: 'pointer', border: '1px solid #cecece', backgroundColor: 'white',
                                        '&:hover': { backgroundColor: '#F8F8F8' }
                                    }}
                                >
                                    <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/smileys/${smiley}`]} alt={smiley}
                                        className={clsx(
                                            "!w-[32px] sm:!w-[34px] md:!w-[36px] lg:!w-[38px] xl:!w-[40px] bg-white",
                                            "!h-[32px] sm:!h-[34px] md:!h-[36px] lg:!h-[38px] xl:!h-[40px]"
                                        )}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    }

    sendPresentDialog = () => {
        const { classes } = this.props;
        const { sendingPresent } = this.state;

        return (
            <Dialog
                maxWidth={"xs"}
                open={Boolean(sendingPresent)}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                        Ismarlama Menüsü
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={2} py={2}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} py={2} px={1}>
                            {`${presentNames[sendingPresent] ?? ""} hediyesini 
                            ${humanize(presentPts[sendingPresent] ?? 0, { delimiter: '.', separator: ',' })} 
                            puan karşılığında göndermek istediğinize emin misiniz?`}
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={() => this.setState({ sendingPresent: null })}
                    >
                        Vazgeç
                    </Button>
                    <Button variant="contained" color="primary" autoFocus
                        sx={{ color: 'var(--white)' }}
                        onClick={() => this.sendPresent()}
                    >
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    presentMenu = () => {
        const { type } = this.props;
        const user = this.props.user;

        const onClose = (e, present) => {
            e.stopPropagation();
            e.preventDefault();

            if (!present)
                this.setState({ presentAnchorEl: null });
            else {

                if (user?.points < presentPts[present]) {
                    const message = 'Bu hediyeyi gönderebilmek için yeterli puanınız yok!';
                    this.props.setNotification({ message, severity: GlobalTypes.NOTIFY_ERR });
                    return;
                }

                this.setState({ sendingPresent: present });
            }
        }

        return (
            <Box position={"absolute"} display={this.state.presentAnchorEl ? "block" : "none"} zIndex={10}
                overflow={"auto"} border={"1px solid #cecece"} bgcolor={"white"}
                onBlur={() => this.setState({ presentAnchorEl: null })}
                sx={{ overscrollBehavior: 'none', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                className={clsx(
                    "max-h-full sm:bottom-14 right-0",
                    isMobile ? "bottom-8" : "bottom-10",
                    type === CHATBOX_TYPES.TABLE ? "right-72 xl:right-96" : "right-0"
                )}
            >
                {/* Header with background */}
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} px={2} py={1}
                    borderBottom={"1px solid #D8D6E8"}
                    className="bg-blue-500"
                >
                    <Typography className={clsx("!text-white !font['Roboto Slab'] !text-xs md:!text-sm lg:!text-base")}>
                        Ismarlama Menüsü
                    </Typography>
                </Box>

                <Presents onSelected={(e, present) => onClose(e, present)} />
            </Box>
        );

        return (
            <Popover open={Boolean(this.state.presentAnchorEl)} anchorEl={this.state.presentAnchorEl}
                onClose={() => onClose()}
                marginThreshold={56}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container
                    className={clsx(
                        "max-h-[75vh]",
                        "!w-[156px] sm:!w-[180px] md:!w-[204px] lg:!w-[228px] xl:!w-[252px]"
                    )}
                >
                    {this.state.presents.map((present) => {
                        return (
                            <Tooltip placement="top" title={`${presentNames[present]} 
                                (${humanize(presentPts[present], { delimiter: '.', separator: ',' })} puan)`}
                            >
                                <Grid item key={present} xs={2} onClick={() => onClose(present)}
                                    sx={{ cursor: 'pointer', border: '1px solid #cecece', '&:hover': { backgroundColor: '#F8F8F8' } }}
                                >
                                    <img src={`${process.env.REACT_APP_CDN_URL}/web/presents/${present}`} alt={present}
                                        className={clsx(
                                            "!w-[24px] sm:!w-[28px] md:!w-[32px] lg:!w-[36px] xl:!w-[40px]",
                                            "!h-[24px] sm:!h-[28px] md:!h-[32px] lg:!h-[36px] xl:!h-[40px]"
                                        )}
                                    />
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            </Popover>
        );
    }

    shrinkChatbox = () => {
        const index = this.heights.indexOf(this.state.height);
        if (index === 0) {
            return;
        }

        if (index === -1) {
            index = 1;
        }

        let h = this.heights[index - 1];
        localStorage.setItem('chatboxHeight', h);

        this.setHeight(h);
    }

    expandChatbox = () => {
        let index = this.heights.indexOf(this.state.height);
        if (index === this.heights.length - 1) {
            return;
        }

        let h = this.heights[index + 1];
        localStorage.setItem('chatboxHeight', h);

        this.setHeight(h);
    }

    onPrivateMessage = (userId, username) => {
        if (!userId) {
            return;
        }

        this.props.addChatTab(userId, username, true);
        this.props.history.push(getPath("/sohbet"), { userId, username });
    }

    onUserSelected = (e, userId) => {
        const { usersById } = this.props;
        this.props.onUserSelected?.(e, usersById[userId]);
    }

    streamInfo = () => {
        const { classes, streamDJUser } = this.props;
        if (!streamDJUser || streamDJUser === 'No DJ') {
            return null;
        }

        const scroll = keyframes`
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
        `

        return (
            <Box className="hidden lg:flex flex-1 px-4 overflow-hidden">
                <Box
                    className="w-full flex"
                    sx={{
                        animation: `${scroll} 15s linear infinite`,
                    }}
                >
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-300")}>
                        ♫&nbsp;
                    </Typography>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-400")}>
                        ♫&nbsp;
                    </Typography>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-500")}>
                        ♫&nbsp;
                    </Typography>

                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-600")}>
                        {`${streamDJUser} Yayında`}
                    </Typography>

                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-500")}>
                        &nbsp;♫
                    </Typography>

                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-400")}>
                        &nbsp;♫
                    </Typography>

                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base !animate-pulse !text-red-300")}>
                        &nbsp;♫
                    </Typography>
                </Box>
            </Box>
        );
    }

    headerActions = () => {
        return (
            <Box display={"flex"}>
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox checked={this.state.autoScroll}
                            onChange={e => this.setState({ autoScroll: e.target.checked })}
                            className="!py-0 !px-0 md:!px-1"
                        />
                    }
                        label={<Typography className={clsx("!text-xs md:!text-sm lg:!text-base")} sx={{ color: '#828282' }}>
                            Kaydır
                        </Typography>
                        }
                    />
                </FormGroup>

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Tooltip title="Sohbeti Temizle" placement="top">
                    <Button variant="contained" onClick={() => this.props.clearChat(this.props.selectedTab)}
                        disableElevation disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            borderRadius: '0 !important', backgroundColor: '#f6fcff !important',
                            p: '2px !important', minWidth: 0, minHeight: 0, ml: 1,
                        }}
                        className="h-6 w-6"
                    >
                        <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/icon_temizle.png`]} alt="temizle" width={18} />
                    </Button>
                </Tooltip>
            </Box>
        );
    }

    bottomDrawer = () => {
        const { classes, messages, selectedTab, blocksById, usersById, rightDrawer, allowExpand } = this.props;

        const msgs = messages[selectedTab] ?? [];

        if (this.state.autoScroll) {
            setTimeout(() => {
                if (this.scrollRef.current) {
                    this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
                }
            }, 100);
        }

        const selectMessage = (msg) => {
            const { userReportActive, reportedMessages } = this.state;

            const onChange = (e) => {
                if (e.target.checked) {
                    this.setState({ reportedMessages: [...reportedMessages, msg] });
                } else {
                    this.setState({ reportedMessages: reportedMessages.filter((element) => element?.id !== msg.id) });
                }
            };

            return (
                <>
                    {msg?.sender_id && userReportActive?.active && userReportActive?.reported_user === msg?.sender_id &&
                        <Checkbox
                            id={msg.id}
                            sx={{ padding: 0 }}
                            checked={reportedMessages.includes(msg)}
                            onChange={onChange}
                        />
                    }
                </>
            )
        };

        const renderMessages = () => {
            let arr = [];
            const { generalSettings } = this.props;

            for (let i = 0; i < msgs.length; i++) {
                const msg = msgs[i];
                if (msg.sender_id in blocksById) {
                    continue;
                }

                if (msg.id in this.msgCache && !this.state.userReportActive.active) {
                    arr.push(this.msgCache[msg.id]);
                    continue;
                }

                const u = { ...usersById[msg.sender_id] };
                const message = this.replaceStringWithEmoji(msg, u);

                const isLastOldMessage = msgs.findIndex(m => m.id === msg.id) === msgs.filter(m => m?.isOldMessage).length - 1;

                const msgEl = (
                    <Box key={msg.id} columnGap={1} alignItems={"center"}
                        className="leading-[12px] sm:leading-[14px] md:leading-[16px] lg:leading-[18px] xl:leading-[20px]"
                    >
                        {selectMessage(msg)}

                        {/* Display date here */}
                        <Typography className={clsx(classes.title, "!text-xs lg:!text-sm")} sx={{ color: 'var(--ultra-violet)', display: 'inline' }}>
                            {generalSettings?.msg_time && <>
                                [{dayjs(msg.ts).format(msg?.isOldMessage ? 'MM/DD HH:mm' : 'HH:mm')}]&nbsp;
                            </>}
                        </Typography>

                        <label for={msg.id}>
                            <Typography className={clsx(
                                classes.title,
                                "leading-none !text-xs md:!text-sm lg:!text-base !font-bold",
                                msg.sender_id ? '' : 'text-[#d84646]',
                                u?.username_color && (!u.svu || u.svu > new Date()) ? `NickRenk${u.username_color}` : '',
                                msg.sender_id || msg.payload?.sender ? '' : '!hidden',
                            )}
                                sx={{
                                    alignSelf: 'start', cursor: msg.sender_id ? 'pointer' : 'default', display: 'inline',
                                    userSelect: this.props.role < roles.Moderator ? 'none' : 'text',
                                }}
                                //onContextMenu={(e) => this.onUserRightClicked(e, msg.sender_id)}
                                onContextMenu={(e) => this.userContextMenuHandler.onContextMenu(e, msg.sender_id)}
                                onTouchStart={(e) => this.userContextMenuHandler.onTouchStart(e, msg.sender_id)}
                                onTouchCancel={this.userContextMenuHandler.onTouchCancel}
                                onTouchEnd={this.userContextMenuHandler.onTouchEnd}
                                onTouchMove={this.userContextMenuHandler.onTouchMove}
                                onDoubleClick={() => this.onPrivateMessage(msg.sender_id, msg.sender)}
                                onClick={(e) => this.onUserSelected(e, msg.sender_id)}
                            >
                                {msg.sender_id ? `<${msg.sender}>` : `[${msg.payload?.sender}] `}
                            </Typography>
                        </label>

                        <Typography className={clsx(
                            classes.title,
                            "!leading-none !text-xs md:!text-sm lg:!text-base pl-[1px]",
                            msg.sender_id ? '' : 'text-[#FF5757]',
                        )}
                            sx={{
                                cursor: msg.sender_id || msg.payload?.userId ? 'pointer' : 'default',
                                display: 'inline', alignSelf: 'start', overflowWrap: 'anywhere',
                                userSelect: this.props.role < roles.Moderator ? 'none' : 'text', color: msg.color ? msg.color : null,
                            }}
                            //onContextMenu={(e) => this.onUserRightClicked(e, msg.sender_id)}
                            onContextMenu={(e) => this.userContextMenuHandler.onContextMenu(e, msg.sender_id ?? msg.payload?.userId)}
                            onTouchStart={(e) => this.userContextMenuHandler.onTouchStart(e, msg.sender_id ?? msg.payload?.userId)}
                            onTouchCancel={this.userContextMenuHandler.onTouchCancel}
                            onTouchEnd={this.userContextMenuHandler.onTouchEnd}
                            onTouchMove={this.userContextMenuHandler.onTouchMove}
                            onDoubleClick={() => this.onPrivateMessage(msg.sender_id, msg.sender)}
                            onClick={(e) => this.onUserSelected(e, msg.sender_id ?? msg.payload?.userId)}
                        >
                            <span className="!leading-none !text-xs md:!text-sm lg:!text-base font-bold">
                                {msg.present ?
                                    (() => {
                                        const present = msg.present;

                                        const message = present?.to ? `${present?.from} tarafından ${present?.to} adlı kullanıcıya ${presentNames[present?.present]} yollandı.` :
                                            `${present?.from} herkese ${presentNames[present?.present]} ısmarladı.`;

                                        return (
                                            <span className={clsx(
                                                u?.text_color && (!u.svu || u.svu > new Date()) ? `YaziRenk${u.text_color}` : '',
                                                "!leading-none !text-xs sm:!text-sm md:!text-base"
                                            )}>
                                                {message}
                                                &nbsp;
                                                <Tooltip placement="top" title={presentNames[present?.present]}>
                                                    <img alt="" src={`${process.env.REACT_APP_CDN_URL}/web/presents/${present?.present}`}
                                                        className="w-[24px] sm:w-[30px] md:w-[36px] lg:w-[42px] xl:w-[48px]"
                                                        style={{ display: 'inline' }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        );
                                    })() :
                                    message
                                }
                            </span>
                        </Typography>

                        {isLastOldMessage && (
                            <Box sx={{ flexDirection: "column", alignItems: "end", justifyContent: 'center', display: "flex", px: 4 }}>
                                <Typography className={clsx(classes.title, "!text-xs md:!text-sm lg:!text-base, text-gray-400 pb-1")}
                                    sx={{ display: 'inline' }}
                                >
                                    Önceki mesajlar
                                </Typography>
                                <Divider className="w-full" />
                            </Box>
                        )}
                    </Box>
                );

                arr.push(msgEl);
                this.msgCache[msg.id] = msgEl;
            }

            const minHeight = this.state.height === 0 ? null : '60px';

            return (
                <Box className={clsx("relative h-full", allowExpand ? "" : "flex-1")}
                    minHeight={allowExpand ? minHeight : null}
                >
                    <Box className="absolute flex flex-col w-full h-full" overflow={"auto"}>
                        <Box ref={this.scrollRef} rowGap={1} py={1} overflow={"auto"}
                            className="h-full px-[2px] md:px-[3px] lg:px-[4px]"
                            zIndex={3} bgcolor={"#f6fcff"}
                            sx={{
                                overscrollBehavior: 'none',
                                '&::-webkit-scrollbar': {
                                    width: '0.4em',
                                    height: 0,
                                },
                            }}
                            onContextMenu={(e) => this.chatboxContextMenuHandler.onContextMenu(e)}
                            onTouchStart={(e) => this.chatboxContextMenuHandler.onTouchStart(e)}
                            onTouchCancel={this.chatboxContextMenuHandler.onTouchCancel}
                            onTouchEnd={this.chatboxContextMenuHandler.onTouchEnd}
                            onTouchMove={this.chatboxContextMenuHandler.onTouchMove}
                        >
                            {/*
                                (
                                    <Box className="flex justify-center">
                                        <Button className="!font['Roboto Slab'] !text-[0.8em] md:!text-[0.9em] lg:!text-[1em]"
                                            variant='contained' sx={{ color: 'var(--white)' }}
                                            onClick={() => this.getOlderMessages()}
                                        >
                                            Daha Fazla
                                        </Button>
                                    </Box>
                                )
                                */
                            }
                            {arr}
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <Box key={"bottom-container"} display={"flex"} flexDirection={"column"} zIndex={1}
                borderTop={"1px solid #a097b3"} flex={1} maxWidth={"100%"} overflow={"hidden"}
            >
                <Box display={"flex"} flexDirection={"column"} flex={1} maxWidth={"100%"} overflow={"hidden"}>

                    {this.props.watermark && (
                        <Box className="relative">
                            <Box zIndex={5} className="absolute top-12 right-2"
                                sx={{ transform: 'translate(0%, 0%)', userSelect: 'none', pointerEvents: 'none' }}
                            >
                                <span className="!font-bold !text-[1.2em] min-[240px]:!text-[1.6em] md:!text-[1.8em] lg:!text-[2em] text-[#5B4F9C66]"
                                    style={{ fontFamily: 'Dancing Script', whiteSpace: 'nowrap', opacity: 0.5 }}
                                >
                                    Kerizim Okey
                                </span>
                            </Box>
                        </Box>
                    )}

                    <Box className="relative">
                        {this.props.hideHeader ? (
                            <Box className="absolute z-[5] top-2 right-2 opacity-100 bg-[#f6fcff]">
                                {this.headerActions()}
                            </Box>
                        ) : (
                            <Box bgcolor={"#f6fcff"} px={2} py={1} borderBottom={"1px solid #D8D6E8"}
                                justifyContent={"space-between"} maxWidth={"100%"} columnGap={1} overflow={"auto"}
                                minHeight={'42px !important'} display={"flex"}
                            >
                                <Box visibility={this.props.hideTitle ? 'hidden' : 'visible'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                    <Box className={clsx("!text-xs md:!text-sm lg:!text-base")} component={"span"}
                                        sx={{ color: '#3A295D' }}
                                    >
                                        {this.props.roomConfig?.title ?? 'Kerizim.com Hoşgeldiniz'}
                                    </Box>
                                </Box>

                                {this.streamInfo()}
                                {this.headerActions()}
                            </Box>
                        )}
                    </Box>

                    {this.state.userReportActive?.active && <Box bgcolor={"#a35858"} px={2} py={1} borderBottom={"1px solid #D8D6E8"} display={"flex"}
                        justifyContent={"space-between"} maxWidth={"100%"} columnGap={1} overflow={"auto"}
                        minHeight={'42px !important'}
                    >
                        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: "flex", alignItems: "center" }}>
                            <Box className={clsx("!text-xs md:!text-sm lg:!text-base text-white xl:text-xl")} component={Typography}
                                sx={{ color: '#FFF', textAlign: "center" }}
                            >
                                Şikayet modu aktif
                            </Box>
                        </Box>

                        <Tooltip title="Raporlamayı iptal et" placement="top">
                            <Button variant='contained'
                                className="!w-8 !h-8 !min-w-8 !min-h-8 !p-0 "
                                sx={{
                                    color: 'var(--white)', bgcolor: '#FFFFFF !important', minWidth: 0,
                                    borderRadius: '8px !important', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25) !important',
                                    '&:hover': {
                                        bgcolor: '#FB8F32 !important',
                                        '& .MuiSvgIcon-root': {
                                            color: '#FFFFFF',
                                        }
                                    },
                                }}
                                onClick={() => this.setState({ userReportActive: { ...this.state.userReportActive, reported_user: null } }, () => this.setState({ userReportActive: {} }))}
                            >
                                <Close htmlColor="#413067" />
                            </Button>
                        </Tooltip>
                    </Box >}

                    {renderMessages()}

                    <Box display={"flex"} alignItems={"center"} bgcolor={"#f6fcff"}
                        className={isMobile ? "py-0 px-0" : "py-2 px-4"}
                    >
                        <Chatbar
                            onRef={(ref) => this.chatbarRef = ref}
                            users={this.props.users}
                            pm={this.props.pm}
                            disabled={this.props.disabled || this.state.userReportActive.active}
                            reporting={this.state.userReportActive.active}
                            label={this.props.label}
                            sendRef={this.sendRef}
                        />

                        <Tooltip title={this.state.userReportActive.active && this.state.reportedMessages.length > 0 ? "Raporu Tamamla" : "Mesajı yolla"} placement="top">
                            <Button variant="contained"
                                onClick={() => {
                                    this.state.userReportActive.active && this.state.reportedMessages.length > 0 ?
                                        this.doOpenReportDialog() :
                                        this.chatbarRef?.onMessageSend()
                                }}
                                sx={{
                                    borderRadius: '0 !important', backgroundColor: '#5B4F9C !important',
                                    borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9',
                                    minWidth: 0, minHeight: 0,
                                }}
                                className="h-8 w-8 sm:h-12 sm:w-12"
                                disabled={this.props.disabled}
                            >
                                {this.state.userReportActive.active && this.state.reportedMessages.length > 0 ? <Check htmlColor="#FFFFFF" /> : <SendIcon htmlColor="#FFFFFF" />}
                            </Button>
                        </Tooltip >

                        <Box className="sm:block">
                            <Tooltip title="Nick Tab" placement="top">
                                <Button variant="contained" onClick={this.chatbarRef?.onTabUser}
                                    disableElevation disableFocusRipple disableRipple disableTouchRipple
                                    sx={{
                                        borderRadius: '0 !important', backgroundColor: '#F8F8F8 !important',
                                        px: '2px !important', border: '1px solid #D9D9D9',
                                        minWidth: 0, minHeight: 0,
                                    }}
                                    className={clsx(
                                        "h-8 w-8 sm:h-12 sm:w-12",
                                        "text-white bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:outline-none shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm text-center"
                                    )}
                                    disabled={this.props.disabled}
                                >
                                    <TabIcon className="w-[18px] md:w-[24px] text-[#5B4F9C]"
                                        style={{ fill: '#FFF' }} />
                                </Button>
                            </Tooltip>
                        </Box>

                        <Focusable
                            focused={Boolean(this.state.smileyAnchorEl)}
                            blurCheck={(e) => Boolean(this.state.smileyAnchorEl)}
                            onBlur={(e) => this.setState({ smileyAnchorEl: null })}
                            onFocus={(e) => this.setState({ smileyAnchorEl: e.currentTarget })}
                            onToggle={(e) => this.setState({ smileyAnchorEl: this.state.smileyAnchorEl ? null : e.currentTarget })}
                            toggleRef={this.smileyButtonRef}
                        >
                            <Box className="sm:block" position={"relative"} ref={this.smileyButtonRef}>
                                <Tooltip title="Emoji yolla" placement="top">
                                    <Button variant="contained"
                                        sx={{
                                            borderRadius: '0 !important',
                                            px: '2px !important', border: '1px solid #D9D9D9', borderLeft: '0',
                                            transformOrigin: 'right bottom', minWidth: 0, minHeight: 0,
                                        }}
                                        className={clsx(
                                            "h-8 w-8 sm:h-12 sm:w-12",
                                            "text-white bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:outline-none shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm text-center"
                                        )}
                                        disableElevation disableFocusRipple disableRipple disableTouchRipple
                                        disabled={this.props.disabled}
                                    >
                                        <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/slightly_smile.png`]} alt="slightly_smile"
                                            className="!w-[24px]"
                                        />

                                    </Button>
                                </Tooltip>
                            </Box>

                            {this.smileyMenu()}
                        </Focusable>


                        <Focusable
                            focused={Boolean(this.state.presentAnchorEl)}
                            blurCheck={(e) => Boolean(this.state.presentAnchorEl)}
                            onBlur={(e) => this.setState({ presentAnchorEl: null })}
                            onFocus={(e) => this.setState({ presentAnchorEl: e.currentTarget })}
                            onToggle={(e) => this.setState({ presentAnchorEl: this.state.presentAnchorEl ? null : e.currentTarget })}
                            toggleRef={this.presentButtonRef}
                        >
                            <Box className="sm:block" ref={this.presentButtonRef}>
                                <Tooltip title="Ismarlama Menüsü" placement="top">
                                    <Button variant="contained"
                                        disableElevation disableFocusRipple disableRipple disableTouchRipple
                                        sx={{
                                            borderRadius: '0 !important',
                                            px: '2px !important', border: '1px solid #D9D9D9', borderLeft: '0',
                                            borderTopRightRadius: rightDrawer ? null : '8px !important', borderBottomRightRadius: rightDrawer ? null : '8px !important',
                                            minWidth: 0, minHeight: 0,
                                        }}
                                        className={clsx(
                                            "h-8 w-8 sm:h-12 sm:w-12",
                                            "text-white bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:outline-none shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm text-center"
                                        )}
                                        disabled={this.props.disabled}
                                    >
                                        <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/coffee.png`]} alt="coffee"
                                            className="!w-[24px]" />
                                    </Button>
                                </Tooltip>
                            </Box>

                            {this.presentMenu()}
                        </Focusable>

                        {
                            rightDrawer ?
                                <Box className="sm:block">
                                    <Tooltip title={rightDrawer.title} placement="top">
                                        <Button variant="contained" onClick={(e) => rightDrawer.onClick(e)}
                                            disableElevation disableFocusRipple disableRipple disableTouchRipple
                                            sx={{
                                                borderRadius: '0 !important', backgroundColor: '#f6fcff !important',
                                                px: '2px !important', border: '1px solid #D9D9D9', borderLeft: '0',
                                                borderTopRightRadius: '8px !important', borderBottomRightRadius: '8px !important',
                                                minWidth: 0, minHeight: 0,
                                            }}
                                            className={clsx(
                                                "h-8 w-8 sm:h-12 sm:w-12",
                                                "text-white bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:outline-none shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm text-center"
                                            )}
                                            disabled={this.props.disabled}
                                        >
                                            {rightDrawer.icon}
                                        </Button>
                                    </Tooltip>
                                </Box>
                                : null
                        }


                        {/*
                        <Box className="sm:block">
                            <Tooltip title="Sohbeti Temizle" placement="top">
                                <Button variant="contained" onClick={() => this.props.clearChat(this.props.selectedTab)}
                                    disableElevation disableFocusRipple disableRipple disableTouchRipple
                                    sx={{
                                        borderRadius: '0 !important', backgroundColor: '#F8F8F8 !important',
                                        px: '2px !important', border: '1px solid #D9D9D9', borderLeft: '0',
                                        borderTopRightRadius: '8px !important', borderBottomRightRadius: '8px !important',
                                        minWidth: 0, minHeight: 0,
                                    }}
                                    className="h-8 w-8 sm:h-12 sm:w-12"
                                    disabled={this.props.disabled}
                                >
                                    <img src={loadedResources[`${process.env.REACT_APP_CDN_URL}/web/img/icon_temizle.png`]} alt="temizle" width={28} />
                                </Button>
                            </Tooltip>
                        </Box>
                        */}
                    </Box >
                </Box >
            </Box >
        );
    }

    heightButtons = () => {
        const { allowExpand } = this.props;

        return (
            <Box display={allowExpand ? "flex" : "none"} position={"absolute"} top={-28} right={0} zIndex={1}>
                <Tooltip title="Sohbeti Daralt">
                    <Button variant="contained" disableElevation disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            width: 28, height: 28, borderRadius: '0 !important', backgroundColor: '#F8F8F8 !important',
                            minWidth: 28, px: '2px !important', border: '1px solid #D9D9D9'
                        }}
                        onClick={() => this.shrinkChatbox()}
                    >
                        <SvgIcon><path d={mdiChevronDoubleDown} /></SvgIcon>
                    </Button>
                </Tooltip>
                <Tooltip title="Sohbeti Genişlet">
                    <Button variant="contained" disableElevation disableFocusRipple disableRipple disableTouchRipple
                        sx={{
                            width: 28, height: 28, borderRadius: '0 !important', backgroundColor: '#F8F8F8 !important',
                            minWidth: 28, px: '2px !important', border: '1px solid #D9D9D9'
                        }}
                        onClick={() => this.expandChatbox()}
                    >
                        <SvgIcon><path d={mdiChevronDoubleUp} /></SvgIcon>
                    </Button>
                </Tooltip>
            </Box>
        );
    }

    renderSlowModeDialog = () => {
        const { classes, roomConfig } = this.props;

        const onClose = (e) => {
            this.setState({ slowModeDialog: false, slowMode: (roomConfig.slowMode ?? 0) / 1000, contextMenuPosition: null });
        }

        return (
            <Dialog
                open={this.state.slowModeDialog}
                onClose={onClose}
                maxWidth={"xs"}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                        Yavaş Mod
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={2} py={2}>
                        {/*
                        Use Slider and label for seconds
                        */}
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} py={2} px={1}>
                            Bir kullanıcının tekrar mesaj gönderebilmesi için geçmesi gereken süre (saniye)
                        </Typography>

                        <Slider
                            value={this.state.slowMode}
                            onChange={(e, v) => this.setState({ slowMode: v })}
                            valueLabelDisplay="auto"
                            step={5}
                            marks
                            min={0}
                            max={60}
                        />

                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} py={2} px={1}>
                            {`Yavaş mod ${this.state.slowMode ?? 0} saniye olarak ayarlandı`}
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={onClose}
                    >
                        Vazgeç
                    </Button>
                    <Button variant="contained" color="primary" autoFocus
                        sx={{ color: 'var(--white)' }}
                        onClick={() => this.setSlowMode()}
                    >
                        Uygula
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderContextMenu = () => {
        const { permission, role, roomConfig, roomMaintenance, type } = this.props;
        const { contextMenuPosition } = this.state;

        const items = [];
        if (type === CHATBOX_TYPES.LOBBY && (permission & Number(1n << 15n)) > 0) {
            if (roomConfig?.allowLobbyChat) {
                items.push(
                    <MenuItem onClick={() => this.changeLobbyChat(false)}>
                        Sohbeti Kapat
                    </MenuItem>
                );
            }
            else {
                items.push(
                    <MenuItem onClick={() => this.changeLobbyChat(true)}>
                        Sohbeti Aç
                    </MenuItem>
                );
            }
        }

        if (type === CHATBOX_TYPES.LOBBY && (permission & Number(1n << 16n)) > 0) {
            items.push(
                <MenuItem onClick={() => this.clearLobbyChat()}>
                    Sohbeti Temizle
                </MenuItem>
            );
        }

        if (role >= roles.Owner) {
            items.push(
                roomMaintenance ?
                    <MenuItem onClick={() => this.endMaintenance()}>
                        Bakım Modunu Kapat
                    </MenuItem> :
                    <MenuItem onClick={() => this.startMaintenance()}>
                        Bakım Modunu Aç
                    </MenuItem>
            );
        }

        if (role >= roles.Admin) {
            items.push(
                <MenuItem onClick={() => this.setState({ slowModeDialog: true, slowMode: (roomConfig?.slowMode ?? 0) / 1000 })}>
                    Yavaş Mod
                </MenuItem>
            );

            items.push(
                <MenuItem onClick={() => this.setState({ sendingGlobalMessage: true })}>
                    Global Mesaj
                </MenuItem>
            );
        }

        if (role >= roles.Moderator && [CHATBOX_TYPES.TABLE, CHATBOX_TYPES.TABLE_MOBILE].includes(type)) {
            items.push(
                <MenuItem onClick={() => this.setState({ disbandingTable: true })}>
                    Masayı Dağıt
                </MenuItem>
            );
            items.push(
                <MenuItem onClick={() => this.setState({ pausingGame: true })}>
                    {`Oyunu ${this.props.tablePaused ? 'Devam Ettir' : 'Duraklat'}`}
                </MenuItem>
            );
        }

        if (items.length === 0) {
            return null;
        }

        return (
            <Menu
                anchorPosition={{ top: contextMenuPosition?.top, left: contextMenuPosition?.left }}
                anchorReference="anchorPosition"
                open={Boolean(contextMenuPosition)}
                onClose={() => this.setState({ contextMenuPosition: null })}
                anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "top" }}
            >
                {items}
            </Menu>
        );
    }

    pausingGameDialog = () => {
        const { classes, tableId, tablePaused } = this.props;

        const onClose = () => {
            this.setState({ pausingGame: false, contextMenuPosition: null });
        }

        const pauseGame = () => {
            this.props.socket.emit('pause_game', { tableId, paused: !tablePaused });
            onClose();
        }

        return (
            <Dialog
                open={this.state.pausingGame}
                onClose={onClose}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                        Oyunu {tablePaused ? 'Devam Ettir' : 'Duraklat'}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={2} py={2}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} py={2} px={1}>
                            {`Oyunu ${tablePaused ? 'devam ettirmek' : 'duraklatmak'} istediğinizden emin misiniz?`}
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={onClose}
                    >
                        Vazgeç
                    </Button>
                    <Button variant="contained" color="primary" autoFocus
                        sx={{ color: 'var(--white)' }}
                        onClick={pauseGame}
                    >
                        {tablePaused ? 'Devam Ettir' : 'Duraklat'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    disbandTableDialog = () => {
        const { classes, tableId } = this.props;

        const onClose = () => {
            this.setState({ disbandingTable: false, contextMenuPosition: null });
        }

        const disbandTable = () => {
            this.props.socket.emit('disband_table', { tableId });
            onClose();
        }

        return (
            <Dialog
                open={this.state.disbandingTable}
                onClose={onClose}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                        Masayı Dağıt
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"} rowGap={2} py={2}>
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")} py={2} px={1}>
                            Masayı dağıtmak istediğinizden emin misiniz?
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={onClose}
                    >
                        Vazgeç
                    </Button>
                    <Button variant="contained" color="primary" autoFocus
                        sx={{ color: 'var(--white)' }}
                        onClick={disbandTable}
                    >
                        Dağıt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    globalMessageDialog = () => {
        const { classes } = this.props;

        const onClose = () => {
            this.setState({ sendingGlobalMessage: false, anonymousGlobalMessage: false, globalMessage: '', contextMenuPosition: null });
        }

        const sendGlobalMessage = () => {
            const { globalMessage } = this.state;

            if (!globalMessage) {
                return;
            }

            this.props.socket.emit('send_announcement', { message: globalMessage, anonymous: this.state.anonymousGlobalMessage });
            this.setState({ sendingGlobalMessage: false, anonymousGlobalMessage: false, globalMessage: '', contextMenuPosition: null });
        }

        return (
            <Dialog
                open={this.state.sendingGlobalMessage}
                onClose={onClose}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                        Global Mesaj
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Box className="pt-2">
                        <Typography className={clsx(classes.text, "!text-xs md:!text-sm lg:!text-base")}>
                            Tüm kullanıcılara gönderilecek bir mesaj yazın.
                        </Typography>
                        <Box display={"flex"} flexDirection={"column"} rowGap={2} pt={2}>
                            <TextField
                                label="Mesaj"
                                variant="outlined"
                                fullWidth
                                value={this.state.globalMessage}
                                onChange={(e) => this.setState({ globalMessage: e.target.value })}
                            />
                            <FormControlLabel control={
                                <Checkbox onChange={(e, checked) => this.setState({
                                    anonymousGlobalMessage: checked
                                })}
                                    checked={this.state.anonymousGlobalMessage}
                                />
                            } label={"İsimsiz olarak gönder"} />
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained"
                        sx={{ color: 'var(--white)' }} color='error'
                        onClick={onClose}
                    >
                        Vazgeç
                    </Button>
                    <Button variant="contained" color="primary" autoFocus
                        sx={{ color: 'var(--white)' }}
                        onClick={sendGlobalMessage}
                    >
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        let { height } = this.state;

        return (
            <>
                <Box position={"relative"}>
                    {this.heightButtons()}
                </Box>
                <Box zIndex={1} display={"flex"} flexWrap={"wrap"} overflow={"visible"}
                    flex={this.state.height / 10}
                    //onContextMenu={this.onContextMenu}
                    sx={{
                        userSelect: 'none !important',
                    }}
                >
                    <Box display={height === 0 ? "none" : "flex"} flexDirection={"column"} overflow={"hidden"} flex={1} maxHeight={this.props.maxHeight}>
                        {this.bottomDrawer()}
                    </Box>

                    {this.sendPresentDialog()}

                    <UserActions
                        room={this.props.selectedTab}
                        pm={Boolean(this.props.pm)}
                        onReport={(v) => this.onUserReport(v)}
                        anchor={this.state.userAnchor}
                        user={this.state.contextUser}
                        anchorPos={this.state.userAnchorPos}
                        onClose={() => this.setState({ userAnchor: null, userAnchorPos: null, contextUser: null }, () => this.userContextRequest = false)}
                        history={this.props.history}
                        onTabUser={this.chatbarRef?.onTabUserWithUsername}
                    />
                </Box>

                {this.renderContextMenu()}
                {this.renderSlowModeDialog()}
                {this.globalMessageDialog()}
                {this.disbandTableDialog()}
                {this.pausingGameDialog()}
            </>
        );
    }
}

Chatbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const selectedTable = state.app.global.selectedTable;

    return {
        messages: state.app.lobby.messages,
        socket: state.app.global.socket,
        selectedTab: state.app.global.selectedTab,
        user: state.app.global.user,
        usersById: state.app.lobby.usersById,
        friendsById: state.app.global.friendsById,
        blocksById: state.app.global.blocksById,
        roomConfig: state.app.global.roomConfig,
        roomMaintenance: state.app.global.maintenance,
        permission: state.app.global.permission,
        role: state.app.global.role,
        generalSettings: state.app.global.generalSettings,
        tablePaused: state.app.lobby.tables[selectedTable]?.game?.paused ?? false,
        streamDJUser: state.app.global.streamInfo.djusername,
    }
};

export default withStyles(styles)(connect(mapStateToProps, {
    clearChat, setNotification, addChatTab, setRoomConfig, setReportDialog, addChatHistory,
    setRoomMaintenance,
})(Chatbox));
